#year-groups {
  width: 100%;
  height: 482px;
  background: url(/i/design/years-bg.png) no-repeat center;
  background-size: cover;
  overflow: hidden;

  h1 {
    color: #343434;
    text-shadow: 0px 4px 0px rgba(0, 0, 0, 0.2);
    margin: 47px 0 34px 0;
    font-size: 35px;
    position: relative;
    background: url(/i/design/year-h2-bg.png) no-repeat bottom center;
    padding: 0 0 17px 0;
    text-indent: 66px;
    font-weight: bold;

    &:before {
      content: '';
      position: absolute;
      left: 35%;
      height: 39px;
      width: 58px;
      background: url(/i/design/year-ic.png) no-repeat;
    }

    span {
      color: #fff;
      font-weight: 700;
    }
  }

  #year-img {
    box-shadow: 0 0 0px 5px rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    float: left;
    margin: 78px 80px 0 0;
  }

  ul.year-list {
    display: block;
    width: 800px;
    float: right;
    margin: auto;

    li {
      background: #fff;
      margin: 0 28px;
      height: 140px;
      width: 140px;
      display: inline-block;
      border-radius: 50%;
      padding: 0;
      text-align: center;
      box-shadow: 0 0 0px 3px rgba(255, 255, 255, 0.5);
      overflow: hidden;

      .single-line {
        line-height: 140px;
        margin: 0;
      }

      a {
        font-size: 25px;
        color: #c7525a;
        font-weight: 900;
        font-family: 'Amaranth', sans-serif;
        text-align: center;
        display: block;
        margin: 24px 0 0 0;
        overflow: hidden;

        span.big-num {
          font-size: 50px;
          padding: 0;
          display: block;
          margin: -17px 0 0 0;
        }

        &:hover {
          transition: 1s;
          transform: scale(1.2);
          text-decoration: none;
        }
      }
    }
  }


}

.second-panel {
  margin: -50px 0 0 0;
}

//Curriculum panel
ul.curriculum-panel {
  display: block;
  text-align: center;
  margin: 0 auto;

  li {
    display: inline-block;
    vertical-align: top;
    margin: 0 50px 30px;
    padding: 0;
    background: none;

    a {
      display: inline-block;
      border-radius: 50%;
      width: 140px;
      height: 140px;
      overflow: hidden;
      position: relative;
      position: relative;
      transition: 1s;

      span.cv-img {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        opacity: 1;
        transition: (all 0.3s ease-in-out);
        border-radius: 50%;
        overflow: hidden;
      }

      span.cv-text {
        content: "";
        position: absolute;
        top: 80px;
        left: 0;
        width: 100%;
        text-align: center;
        font-size: 16px;
        font-weight: 700;
        color: #a6363e;
        opacity: 0;
        transition: (all 0.3s ease-in-out);
        font-family: 'Amaranth', sans-serif;
        text-shadow: 1.4px 1.4px 0px rgba(0, 0, 0, 0.4);
      }

      span.double-line {
        line-height: 120%;
      }

      //Hover state
      &:hover {
        transform: scale(1.2);
        transition: 1s;

        span.cv-text {
          top: 52px;
          opacity: 1;
          transition: (all 0.3s ease-in-out);
          font-size: 25px;
        }

        span.double-line {
          top: 43px;
        }
      }
    }


    .yellow {
      box-shadow: inset 0 0 0 6px #343434;
      //&:hover{box-shadow:inset 0 0 0 100px #343434;}
    }

  }
}