//iPad basic
@media only screen and (max-width: 1024px) {

	// 	//Wrapper
	// .wrapper{width: 950px;}
	//
	// //Nav wrapper
	// nav#navholder > .wrapper{width: 100%;}
	// ul.nav{width: 100%;}
	//
	//
	// //Mainholder
	// #mainholder{width: 950px;}
	//
	// //Contents
	// .Content1Column{width: 950px;}

}

// Mobile Version

@media only screen and (max-width: 860px) {

	// Body

	body {
		min-width: 0;
		overflow-x: hidden;
		//font-size: 13px;
	}

	// Global

	img {
		max-width: 100%;
		height: auto;
	}

	table {
		max-width: 100%;
	}

	//Responsive tables
	table.tableborder {
		width: 100%;
		overflow: scroll;
		white-space: nowrap;
		table-layout: fixed;
		position: relative;

		&:before {
			content: "";
			width: 30px;
			height: 100%;
			position: absolute;
			top: 0;
			right: -1px;
			background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.2) 100%);
			background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.2) 100%);
			background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.2) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#33000000', GradientType=1);
		}

		tbody {
			width: 100%;
			float: left;
			white-space: normal;
			overflow-x: scroll;
		}
	}

	// Wrappers
	.wrapper {
		width: 100%;
	}

	// Calendar

	#calendar {
		.fc-header td {
			display: block;
			width: 100%;
		}

		h2 {}

		.fc-button-content {
			font-family: Arial;
		}

		.fc-header-right {
			text-align: left;
		}

		.fc-header-center {
			text-align: left;
		}
	}

	// Forms

	form div input[type="text"],
	form div textarea,
	form span textarea,
	form div input[type="textarea"],
	form div input[type="password"] {
		width: 200px;
	}

	form div span.button {
		padding-left: 0;
	}

	//Search Form
	#FS {
		display: none;
	}

	.translate {
		display: none;
	}

	#map {
		display: none;
	}

	#slideOut {
		display: none;
	}

	// Header
	header {
		.wrapper {
			padding: 0;
		}
	}

	img#logo {
		margin: 50px auto 0;
		width: 95%;
	}

	//Slider
	#slider {
		height: 400px !important;
	}

	// Mainholder
	#mainholder {
		width: 100%;
		padding: 0;
	}

	.welcome {
		width: 90%;
		padding: 5%;

		h1 {
			margin: 5% auto;
			text-indent: 0;
			padding: 0;

			&:before {
				display: none;
			}
		}

		p {
			text-align: center;
		}

		a.more {
			margin: auto;
		}
	}

	.values-statement {
		background: #76b9b1;
		width: 90%;
		padding: 5%;

		&:before {
			display: none;
		}

		h2 {
			margin: 5% auto;
			text-indent: 0;
			padding: 0;

			&:before {
				display: none;
			}
		}
	}

	.our-vision {
		width: 90%;
		padding: 5%;

		h2 {
			margin: 5% auto;
			text-indent: 0;
			padding: 0;

			&:before {
				display: none;
			}
		}

		p {
			text-align: center;
		}
	}

	// Contents
	.Content1Column,
	.Content2Column,
	.Content3Column {
		width: 80%;
		padding: 5%;
		margin: 5%;
	}

	// Col Left and Right
	.ColumnLeft,
	.ColumnRight {
		width: 80%;
		padding: 5%;
		margin: 5%;
	}

	//Home page alert
	.modal-home {
		.message-modal {
			width: 90%;
			margin: 0 5%;
			left: 0;
			top: 20%;
			padding: 0;

			.modal-content {
				.modal-text {
					padding: 3%;
				}

				a.close-modal {
					position: absolute;
					top: 3%;
					right: 3%;
					background: #fff;
					padding: 3px;
					border-radius: 0px 0px 0px 7px;
				}
			}
		}
	}

	#diary-dates {
		height: auto;
	}

	#SideHeadingDiary {
		h2 {
			width: 80%;
			margin: 5% auto;
			text-indent: 0px;

			&:before {
				display: none;
			}
		}

		ul.SideList li {
			width: 80%;
			display: block;
			margin: auto;
		}
	}

	#year-groups {
		height: auto;

		h1 {
			width: 80%;
			margin: 5% auto;
			text-indent: 0px;

			&:before {
				display: none;
			}
		}
	}

	// Footer
	footer {
		height: auto;

		.wrapper {
			width: 90%;
			padding: 5%;

			h2 {
				text-align: center;
				margin: auto;
				background: url(/i/design/footer-h2.png) no-repeat center bottom;

				&:before {
					display: none;
				}
			}

			p {
				text-align: center;
				margin: 10px auto;

				&:before {
					display: none;
				}
			}
		}
	}

	a.footer-email {
		margin: 5px auto;
	}

	.left-footer {
		width: 100%;
		background: none;
	}

	.sub-footer {
		height: auto;
		line-height: normal;

		p {
			float: none !important;
			margin: 10px auto;
			text-align: center !important;
		}
	}

}