/* MeanMenu 2.0.7: */
/* To be used with jquery.meanmenu.js by Chris Wharton (http://www.meanthemes.com/plugins/meanmenu/) */

// -----------------------------------//
// VARIABLES FOR EASY CUSTOMISATION
// -----------------------------------//


// Reveal Icon & Text:
$mm-reveal-color: #fff;
$mm-reveal-background: #76b9b1;

// Navigation Background:
$mm-navigation-background: #76b9b1;

// Do you want to the borders to be 'darker' or 'lighter'?
$mm-border-theme: darker;

// List Items:
$mm-li-color: #fff;
$mm-li-font: 12px 'Arial', Helvetica, sans-serif;
$mm-li-line-height: 22px;

// Expand / Collapse Boxes
$mm-expand-color: #fff;


// -----------------------------------//
// BORDERS AND EXPAND ICON :HOVER STATE
// -----------------------------------//


@function lighten-opacity ($opacity) {
	@if $mm-border-theme ==lighter {
		@return rgba(255, 255, 255, $opacity/2);
	}

	@else if $mm-border-theme ==darker {
		@return rgba(0, 0, 0, $opacity/2);
	}
}

@function darken-opacity ($opacity) {
	@if $mm-border-theme ==lighter {
		@return rgba($mm-navigation-background, $opacity);
	}

	@else if $mm-border-theme ==darker {
		@return rgba($mm-navigation-background, $opacity);
	}
}


// -----------------------------------//
// ADVANCED CUSTOMISATION VARIABLES
// -----------------------------------//


// Reveal Text

$mm-reveal-font-size: 16px;
$mm-reveal-font-family: 'Arial', Helvetica, sans-serif;
$mm-reveal-font-weight: bold;

// List Items:
$mm-li-padding-top: 10px;
$mm-li-padding-bottom: 10px;

// Expand / Collapse Boxes
$mm-expand-button-padding-top: 5px;
$mm-expand-button-padding-bottom: 5px;


// -----------------------------------//
// FULL SASS BELOW
// -----------------------------------//


// Hide mobile nav until viewport is reached

a.meanmenu-reveal {
	display: none;
}

// MeanMenu Container

.mean-container .mean-bar {
	float: left;
	width: 100%;
	max-height: 100%;
	position: fixed;
	overflow-y: scroll;
	background: $mm-reveal-background;
	padding: 0;
	min-height: $mm-li-padding-top+$mm-li-padding-bottom+24;
	z-index: 999999;
	top: 0;
}

// Reveal Container

.mean-container a.meanmenu-reveal {
	width: 80px;
	height: 22px;
	padding: $mm-li-padding-top+2 13px $mm-li-padding-bottom 13px;
	position: absolute;
	top: 0;
	right: 0;
	cursor: pointer;
	color: $mm-reveal-color;
	text-decoration: none;
	font-size: 16px;
	line-height: 22px;
	display: block;
	font-family: Arial, Helvetica, sans-serif;
	font-weight: 700;
}

// Reveal Container > Icon & Text

.mean-container a.meanmenu-reveal div {
	&.mm-hamburger-icon {
		float: left;
		height: 22px;
		width: 22px;

		span {
			display: block;
			background: $mm-reveal-color;
			height: 3px;
			font-size: 1px;
			text-indent: -99999px;
			margin-top: 3px;
		}
	}

	&.mm-open {
		float: right;
		font-family: $mm-reveal-font-family;
		font-size: $mm-reveal-font-size  !important;
		font-weight: $mm-reveal-font-weight;
	}
}

// Navigation Setup

.mean-container .mean-nav {
	float: left;
	width: 100%;
	background: $mm-navigation-background;
	margin-top: $mm-li-padding-top+$mm-li-padding-bottom+24;

	ul {
		padding: 0;
		margin: 0;
		width: 100%;
		list-style-type: none;

		li {
			position: relative;
			float: left;
			width: 100%;
			margin: 0;
			padding: 0;
			background: none;
		}
	}
}

// Navigation > First Level Links

.mean-container .mean-nav ul li a {
	display: block;
	float: left;
	width: 90%;
	padding: $mm-li-padding-top 5% $mm-li-padding-bottom 5%;
	margin: 0;
	text-align: left;
	color: $mm-li-color;
	border-top: 1px solid #999;
	border-top: 1px solid lighten-opacity(0.5);
	font: $mm-li-font;
	line-height: $mm-li-line-height;
	text-decoration: none;
	text-transform: uppercase;

	&:hover {
		background: #252525;
		background: lighten-opacity(0.1*2);
	}
}

// Navigation > Second Level Links

.mean-container .mean-nav ul li li a {
	width: 80%;
	padding: $mm-li-padding-top 10% $mm-li-padding-bottom 10%;
	border-top: 1px solid #999;
	border-top: 1px solid lighten-opacity(0.25);
	opacity: 0.75;
	filter: alpha(opacity=75);
	text-shadow: none !important;
	visibility: visible;
}

// Navigation > Third Level Links

.mean-container .mean-nav ul li li li a {
	width: 70%;
	padding: $mm-li-padding-top 15% $mm-li-padding-bottom 15%;
}

// Navigation > Expand Icon

.mean-container .mean-nav ul li a.mean-expand {
	margin-top: 1px;
	width: 26px;
	height: 32px;
	line-height: 32px;
	padding: $mm-expand-button-padding-top 12px $mm-expand-button-padding-bottom 12px !important;
	text-align: center;
	position: absolute;
	color: $mm-expand-color;
	right: 0;
	top: 0;
	z-index: 2;
	font-weight: 700;
	background: lighten-opacity(0.1);
	border: none !important;
	border-left: 1px solid #999;
	border-left: 1px solid lighten-opacity(0.4) !important;
	border-bottom: 1px solid #999;
	border-bottom: 1px solid lighten-opacity(0.2) !important;

	&:hover {
		background: lighten-opacity(0.26);
	}
}

// Misc: Dont edit. Push container height etc. to be edited in the JS.

.mean-container .mean-push {
	float: left;
	width: 100%;
	padding: 0;
	margin: 0;
	clear: both;
}

.mean-nav .wrapper {
	width: 100%;
	padding: 0;
	margin: 0;
}

// Fix for box sizing

.mean-container .mean-bar,
.mean-container .mean-bar * {
	box-sizing: content-box;
}

.mean-remove {
	display: none !important;
}