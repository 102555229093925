@import url(https://fonts.googleapis.com/css?family=Lato:400,700,300italic,400italic);

// -----------------------------------//
// ELEMENTS COLOURS
// -----------------------------------//
$slideOut-panel-bg: rgba(56, 74, 159, 0.8);

$link-bg-colour: #fff;
$link-bg-colour-hover: #ebebeb;
$links-colour: #444444;

$slide-click-colour: #444444;


/* Full container */
#slideOut {
	position: fixed;
	width: auto;
	height: auto;
	min-height: 240px;
	top: 50%;
	left: -250px;
	font-family: 'Lato', sans-serif;
	padding: 0;
    z-index: 50;
}


/* Click to slide */
#slideClick {
	float: right;
	height: 100%;
	width: 70px;
	cursor: pointer;
	position: relative;
	background: rgba(154, 190, 191, 0.8);

	span{
		width: 160px;
		height: 70px;
		position: absolute;
		top: 50%;
		left: 70%;
		font-size: 22px;
		color: #fff;
		text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);
		white-space: nowrap;
		font-weight: normal;
		transform: rotate(-90deg);
		margin-left: -73px;
		margin-top: -37px;
		strong { font-weight: 900; }
		&:before{
			content: "";
			width: 16px;
			height: 49px;
			background: url(/i/design/arrow-open-close.png) no-repeat center center;
			position: absolute;
			left: 48%;
			top: 46%;
			transform: rotate(90deg);
		}
	}
}

/* Content links */
#slideContent {
	width: auto;
	height: 100%;
	padding: 0;
	margin: 0;
	float: left;
	color: #EFEFEF;
	text-align: center;
	display: table !important;
		background: $slideOut-panel-bg;
}


/* Box with links  */
ul.quick-links{
	width: 238px;
	display: table-cell;
	vertical-align: middle;
	text-align: center;
	overflow: hidden;

	li{
		background: none;
		margin: 0;
		padding: 0;
		display: block;

		a{
			position: relative;
			background: url(/i/design/quick-line.png) no-repeat left 30px bottom;
			color: #fff;
			font-size: 18px;
			display: block;
			text-align: left;
			padding: 5px 10px 5px 58px;

			&:before {
				content: '';
				position: absolute;
				left: 30px; top: 15px;
				background: #fff;
				height: 7px; width: 7px;
				border-radius: 50%;
				transition: 1s;
			}
			&:hover{
				text-decoration: none;
				&:before { left: 10px; transition: 1s;}
			}
		}
	}
}

/* Change the arrow  */
// .open span::before{transform: rotate(-90deg) !important;}
.flip { display: none; }

@media only screen and (max-width: 750px) {
	#slideOut{
		position: relative;
		top: 0;
		left: 0;
		padding: 5px 3% 0 2%;
		overflow: visible;
		height: auto !important;
		box-shadow: 0 0 0 1px rgba(0,0,0,0.08);
		border-radius: 0 0 10px 10px;
		margin: 0 5%;
	}

	#slideClick{
		display: none;
		span{
			transform: rotate(0);
			position: absolute;
			top: 0;
			left: 0;
			width: auto;
			height: auto;
			margin-left: 0;
			margin-top: 0;
			position: relative;
		}
	}

	#slideContent{
		float: none;
	}

	ul.quick-links{
		width: 100%;
		li{
			a{
				padding: 6px 0 6px 20%;
				font-size: 1.1em;
				background-position: 8% 10px;
			}
		}
	}


	.flip{
		z-index: 800;
		margin: 0;
		padding:0 0 5px 0;
		cursor: pointer;
		font-size: 16px;
		color: $slide-click-colour;
		display: block;

		img{display: block; margin: 0 auto; transform: rotate(90deg);}
	}

	.open img{transform: rotate(-90deg);}
}
