@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,200;0,300;0,400;500;0,600;0,700;0,800;1,200;1,300;1,400;1,600;1,700&display=swap');

// -----------------------------------//
// GLOBAL STYLES
// -----------------------------------//

// BODY
body {
	color: #343434;
	font-size: 16px;
	font-family: 'Raleway', sans-serif;
	text-align: center;
	line-height: 1.528571429;
	padding: 0px;
	margin: 0;
	font-weight: 400;

}

// LINKS
body a {
	color: #253672;
	text-decoration: none;
}

body a:hover,
body a:focus {
	text-decoration: underline;
}

// LINKED IMAGES
a img,
img a,
img,
p a img {
	border: 0;
	border-bottom: 0;
	text-decoration: none;
	vertical-align: middle;
}

a img:hover {
	opacity: 0.8
}

// HEADINGS
h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: bold;
	text-align: center;
}

h1 {
	font-size: 35px;
	margin: 0 0 20px 0;
	color: #253672;
	line-height: 1.2;
	text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.35);
}

h2 {
	font-size: 25px;
	margin: 25px 0 20px 0;
	color: #253672;
	line-height: 1.3;
}

h3 {
	font-size: 15px;
	margin: 25px 0 10px 0;
	padding: 10px;
	background: #76b9b1;
	color: #fff;
}

h4 {
	@extend h3;
	background-position: 10px center;
	background-repeat: no-repeat;
	padding-left: 30px;
}

h4.open {
	background-image: url(/i/design/expanding-faq/minus-white.png);
}

h4.closed {
	background-image: url(/i/design/expanding-faq/plus-white.png);
}

h5 {
	font-size: 12px;
}

h6,
small {
	font-size: 10px;
}

h1+h2 {
	margin-top: 0;
	border: 0;
	padding: 0;
}

h1+p,
h2+p,
h3+p {
	margin-top: 0;
}

// PARAGRAPHS AND LIST ITEMS
p {
	margin: 12px 0;
	padding: 0;
}

li {
	margin: 2px 0;
	padding: 0 0 0 23px;
	background-image: url(/i/icons/bullet_black.png);
	background-repeat: no-repeat;
}

// FORMS
form {
	margin: 0;
	padding: 0;
	@extend .clearfix;
}

fieldset {
	border: 1px solid #c0c0c0;
	margin: 0 2px;
	padding: 0.35em 0.625em 0.75em;
}

legend {
	border: 0;
	padding: 0;
	white-space: normal;
	*margin-left: -7px;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
	-webkit-appearance: button;
	cursor: pointer;
	*overflow: visible;
	/* 4 */
}

button[disabled],
html input[disabled] {
	cursor: default;
}

input[type="checkbox"],
input[type="radio"] {
	box-sizing: border-box;
	padding: 0;
	*height: 13px;
	*width: 13px;
}

input[type="search"] {
	-webkit-appearance: textfield;
	box-sizing: content-box;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
	-webkit-appearance: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
	border: 0;
	padding: 0;
}

textarea {
	resize: vertical;
	overflow: auto;
	vertical-align: top;
}

label {
	float: left;
	margin: 10px 0;
	width: 90px;
	display: inline;
}

form .formfield {
	margin: 10px;
	float: left;
	display: inline;
	width: 200px;
	border: 1px solid #CCC;
	padding: 2px;
}

form div {
	margin: 15px 0;
	display: block;
	clear: left;
	vertical-align: top;
}

form div input[type="text"],
form div textarea,
form span textarea,
form div input[type="textarea"],
form div input[type="password"] {
	border: 1px solid #CCC;
	padding: 4px;
	width: 300px;
	margin: 0;
	background-color: #EFEFEF;
}

input[type="text"]:focus,
form div textarea:focus,
form span textarea:focus,
form div input[type="textarea"]:focus,
form div input[type="password"]:focus {
	border-color: #000;
}

form div span.label label,
form div span.label,
span.spacer,
span.multiple span {
	width: 180px;
	float: left;
	margin: 0 0 0 0;
}

form div span.multiple {
	float: left;
}

form div span.button {
	padding-left: 180px;
	background: transparent;
	border: 0;
}

form div span.button input {
	background: #666;
	color: #FFF;
	margin: 0;
	padding: 8px 10px;
	font-weight: bold;
	border: 0;
}

form div span.button input:hover {
	background: #888;
}

form div.clear {
	clear: both;
	padding-top: 5px;
}

// -----------------------------------//
// WRAPPER
// -----------------------------------//

#wrapper {
	margin: 0 auto;
	text-align: center;
}

.wrapper {
	width: 990px;
	margin: 0 auto;
	height: 100%;
	@extend .clearfix;
}

// -----------------------------------//
// HEADER
// -----------------------------------//

header {
	position: relative;
	z-index: 99;

	// &:before {
	// 	content: '';
	// 	position: absolute;
	// 	left: 0;
	// 	top: 0;
	// 	height: 300px;
	// 	width: 100%;
	// 	background-image: -webkit-linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
	// 	background-image: -ms-linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
	// 	z-index: -1;
	// }


}

header>.wrapper {
	padding: 0;
	position: relative;
	z-index: 60;
}

header h1 {
	margin: 0;
	background: none;
}

#logobar {
	margin: 0;
	padding: 0;
}

img#logo {
	margin: 20px auto 0;
	display: block;
}

a.twitter-header {
	height: 24px;
	width: 24px;
	position: absolute;
	top: 11px;
	right: 78px;
	background: url(/i/design/twitter-ic.png) no-repeat;
}

// -----------------------------------//
// SEARCH FORM
// -----------------------------------//
#FS {
	position: absolute;
	right: 0px;
	top: 11px;
	z-index: 999;
}

#FS input {
	outline: none;
	color: #fff;
}

#FS input[type=search] {
	-webkit-appearance: textfield;
	-webkit-box-sizing: content-box;
	font-family: inherit;
	font-size: 100%;
}

#FS input::-webkit-search-decoration,
#FS input::-webkit-search-cancel-button {
	display: none;
}

#FS input[type=search] {
	color: transparent;
	cursor: pointer;
	background: url(/i/design/search-ic.png) no-repeat 0 center transparent;
	padding: 0px;
	width: 24px;
	height: 24px;
	box-shadow: inset 2px 2px 0 0 rgba(0, 0, 0, .15);
	border-radius: 30px;
	text-indent: 5px;
	transition: all .5s;
}

#FS input[type=search]:hover {
	background-color: transparent;
}

#FS input[type=search]:focus {
	width: 150px;
	background-color: #fff;
	padding-left: 32px;
	color: #000;
	cursor: auto;
	font-weight: 400;
	box-shadow: inset 2px 2px 0 0 rgba(0, 0, 0, .15);
}

#FS input[type=search]:focus:-moz-placeholder {
	color: #fff;
	font-weight: 300;
	-webkit-transition: .3s ease-out;
	transition: .3s ease-out;
}

#FS input[type=search]:focus::-webkit-input-placeholder {
	color: #fff;
	font-weight: 300;
	-webkit-transition: .3s ease-out;
	transition: .3s ease-out;
}

#FS input:-moz-placeholder {
	color: transparent;
	-webkit-transition: .3s ease-out;
	transition: .3s ease-out;
}

#FS input::-webkit-input-placeholder {
	color: transparent;
	-webkit-transition: .3s ease-out;
	transition: .3s ease-out;
}

// -----------------------------------//
// SEARCH RESULTS
// -----------------------------------//
$search-highlight-background-color: #253672;
$search-highlight-color: #fff;
$search-highlight-font-weight: bold;
$search-highlight-font-size: 15px;
$search-odd-tr-background: #ececec;

.search-subject-box {
	display: inline-block;
	background: #f1f1f1;
	padding: 5px 15px;
	font-size: 16px;
	border-radius: 4px;
	border: 1px solid #ccc;
	text-transform: uppercase;
}

.search-numbers {
	text-transform: uppercase;
	font-weight: bold;
	font-size: 12px;

	span {
		background: #f1f1f1;
		padding: 3px 8px;
		margin: 0 3px;
		border-radius: 2px;
		border: 1px solid #ccc;
	}
}

// -----------------------------------//
// GOOGLE TRANSLATE WIDGET
// -----------------------------------//

#google_translate_element {
	opacity: 0;
}

.translate {
	background: url(/i/design/translate-ic.png) no-repeat;
	height: 24px;
	width: 24px;
	position: absolute;
	top: 11px;
	right: 38px;
	overflow: hidden;
}

// -----------------------------------//
// MAIN HOLDER
// -----------------------------------//


body.body_3759 #mainholder {
	width: 100%;
	background: #fff;
	padding: 30px 0 0 0;
}

#mainholder {
	width: 990px;
	margin: 0px auto;
	padding: 30px 0 30px 0;
	clear: both;
	text-align: left;
	@extend .clearfix;
}

// -----------------------------------//
// CONTENT COLUMNS
// -----------------------------------//

.welcome {
	width: 650px;
	float: left;
	position: relative;
	z-index: 9;

	@media only screen and (max-width: 1100px) {
		margin: 0 0 0 50px;
	}

	@media only screen and (max-width: 860px) {
		margin: 0;
	}

	h1 {
		color: #343434;
		text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.35);
		margin: 12px 0 33px 0;
		font-size: 35px;
		position: relative;
		background: url(/i/design/year-h2-bg.png) no-repeat bottom center;
		padding: 0 0 16px 0;
		text-align: center;
		text-indent: 33px;

		&:before {
			content: '';
			position: absolute;
			left: 10%;
			height: 40px;
			width: 40px;
			background: url(/i/design/welcome-ic.png) no-repeat;
		}

		span {
			color: #3b4b9b;
			font-weight: 800;
		}
	}

	p {
		line-height: 1.75;
		margin-bottom: 15px;
		width: 98%;
		letter-spacing: 0.01em;
	}

	a.more {
		width: 150px;
		height: 33px;
		line-height: 33px;
		display: block;
		background: #384AA0;
		color: #fff;
		text-align: center;
		font-size: 18px;
		transition: 1s;

		&:hover {
			transition: 1s;
			background: #76b9b1;
		}
	}
}


.values-statement {
	width: 650px;
	float: right;
	margin: 26px 0 6px 0;
	position: relative;

	@media only screen and (max-width: 1100px) {
		margin: 0 160px 0 0;
	}

	@media only screen and (max-width: 860px) {
		margin: 0 auto;
	}

	&:before {
		content: '';
		position: absolute;
		width: 760px;
		height: 760px;
		right: -54px;
		top: -192px;
		border-radius: 50%;
		background: #76b9b1;
		z-index: 1;
	}

	h2 {
		color: #343434;
		text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.35);
		margin: 25px 0 32px 0;
		font-size: 35px;
		position: relative;
		background: url(/i/design/year-h2-bg.png) no-repeat bottom center;
		padding: 0 0 16px 0;
		text-align: center;
		z-index: 2;
		text-indent: 95px;
		letter-spacing: 0.04em;

		&:before {
			content: '';
			position: absolute;
			left: 13%;
			height: 45px;
			width: 52px;
			background: url(/i/design/mission-ic.png) no-repeat;
		}

		span {
			color: #fff;
			font-weight: 800;
		}
	}

	p {
		line-height: 1.75;
		margin-bottom: 15px;
		width: 98%;
		letter-spacing: 0.01em;
		position: relative;
		z-index: 2;
		text-align: center;
		color: #fff;
	}

	a.more {
		width: 150px;
		height: 33px;
		line-height: 33px;
		display: block;
		background: #384AA0;
		color: #fff;
		text-align: center;
		font-size: 18px;
		transition: 1s;
		margin: 0 auto;
		position: relative;
		z-index: 999;

		&:hover {
			transition: 1s;
			background: #fff;
			color: #384AA0;
		}
	}

}


.our-vision {
	width: 650px;
	float: left;
	position: relative;
	z-index: 9;

	@media only screen and (max-width: 1100px) {
		margin: 0 0 0 50px;
	}

	@media only screen and (max-width: 860px) {
		margin: 0;
		width: auto;
		text-align: center;
	}

	h2 {
		color: #343434;
		text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.35);
		margin: 14px 0 34px 0;
		font-size: 35px;
		position: relative;
		background: url(/i/design/year-h2-bg.png) no-repeat bottom center;
		padding: 0 0 14px 0;
		text-align: center;
		text-indent: 54px;

		@media only screen and (max-width: 860px) {
		text-indent: 0;
		}
		

		&:before {
			content: '';
			position: absolute;
			left: 33%;
			height: 40px;
			width: 42px;
			background: url(/i/design/aims-ic.png) no-repeat;

			@media only screen and (max-width: 860px) {
				display: none;
			}
		}

		span {
			color: #384AA0;
			font-weight: 800;
		}
	}

	p {
		line-height: 1.75;
		margin-bottom: 10px;
		width: 99%;
		letter-spacing: 0.01em;

		&:last-of-type {
			margin-bottom: 50px;
		}
	}


	.vision-bold {
		font-weight: bold;
	}


	.vision-values {
		display: grid;
		grid-template-columns: repeat(3, 1fr) repeat(3, 0);
		grid-template-rows: repeat(2, 1fr) repeat(2, 0);
		justify-items: center;
		row-gap: 10px;

		@media only screen and (max-width: 630px) {
			grid-template-columns: repeat(2, 1fr);
			grid-template-rows: repeat(3, 1fr);
			grid-column-gap: 0px;
			grid-row-gap: 0px;
		}

		li {
			display: inline-block;
			padding: 0;
			background: none;
			text-align: center;
			color: #fff;
			height: 68px;
			width: 178px;
			line-height: 68px;
			border-radius: 20px;
			font-weight: bold;

			&:nth-of-type(1) {
				background: #76b9b1;
				grid-area: 1 / 1 / 2 / 2;

				@media only screen and (max-width: 630px) {
					grid-area: 1 / 1 / 2 / 2;
				}
			}

			&:nth-of-type(2) {
				background: #3ca794;
				grid-area: 1 / 2 / 2 / 3;

				@media only screen and (max-width: 630px) {
					grid-area: 1 / 2 / 2 / 3;
				}
			}

			&:nth-of-type(3) {
				background: #238187;
				grid-area: 1 / 3 / 2 / 4;

				@media only screen and (max-width: 630px) {
					grid-area: 2 / 1 / 3 / 2;
				}
			}

			&:nth-of-type(4) {
				background: #238187;
				grid-area: 2 / 1 / 3 / 2;

				@media only screen and (max-width: 630px) {
					grid-area: 2 / 2 / 3 / 3;
				}
			}

			&:nth-of-type(5) {
				background: #3ca794;
				grid-area: 2 / 2 / 3 / 3;

				@media only screen and (max-width: 630px) {
					grid-area: 3 / 1 / 4 / 2; 
				}
			}

			&:nth-of-type(6) {
				background: #76b9b1;
				grid-area: 2 / 3 / 3 / 4;

				@media only screen and (max-width: 630px) {
					grid-area: 3 / 2 / 4 / 3;
				}
			}
		}
	}
}


//Content 1
.Content1Column {
	width: 930px;
	margin: 0;
	padding: 30px;
	float: left;
	min-height: 350px;
	text-align: left;
	background: rgba(255, 255, 255, 0.902);

	img {
		max-width: 100%;
		height: auto;
	}
}

//Content 2
.Content2Column {
	width: 665px;
	margin: 0;
	padding: 30px;
	float: left;
	min-height: 350px;
	text-align: left;
	background: rgba(255, 255, 255, 0.902);

	img {
		max-width: 100%;
		height: auto;
	}
}

//Content 3
.Content3Column {
	width: 430px;
	margin: 0;
	padding: 0;
	float: left;
	min-height: 350px;
	text-align: left;

	img {
		max-width: 100%;
		height: auto;
	}
}

.HomeAlert .Content1Column {
	float: none;
	margin: 0 auto;
}

// -----------------------------------//
// COLUMN LEFT & COLUMN RIGHT
// -----------------------------------//

.ColumnLeft {
	width: 310px;
	margin: 0;
	padding: 0 0 0 0;
	min-height: 250px;
	text-align: left;
	float: left;
}

.ColumnRight {
	width: 230px;
	margin: 0;
	padding: 0 0 0 0;
	min-height: 250px;
	text-align: left;
	float: right;
}

.ColumnLeft ul,
.ColumnRight ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

.ColumnLeft nav ul a:link,
.ColumnRight nav ul a:visited,
.ColumnRight nav ul a:link,
.ColumnRight nav ul a:visited {
	display: block;
}

.ColumnLeft li {
	border-bottom: 1px solid #EEE;
}

// Hack to fix IE/Win's broken rendering of block-level anchors in lists
html>body .ColumnLeft li {
	border-bottom: none;
}

// Fix for browsers that don't need the hack

// -----------------------------------//
// SIDE COLUMN > LIST SECTIONS
// -----------------------------------//

// Container
.SideHeading {
	margin: 30px 0 0 0;
	padding: 0;
	text-align: center;
}

.SideHeading:first-child {
	margin-top: 0;
}

// Heading
.SideHeading h2 {
	display: block;
	text-align: center;
	font-size: 24px;
	padding: 20px 0;
	margin: 0;
}

// List
ul.SideList {
	margin: 0 0 0 0;
	padding: 0;
	border: 0;
	list-style: none;
	text-align: left;
}

ul.SideList li {
	margin: 0 0 0 0;
	padding: 0;
	background: none;
}

ul.SideList li a {
	margin: 0;
	padding: 5px 10px;
	font-weight: normal;
	display: block;
	transition: 1s;
}

ul.SideList li a:hover {
	transition: 1s;
	text-decoration: none;
}

ul.SideList li a.child {
	background: url(/i/design/lite/bg_nav_li_a_child.png) left 20px center no-repeat;
	padding-left: 40px !important;

}


// Date and Title
ul.SideList li p {
	margin: 0;
	padding: 0;
}

ul.SideList p.date {
	text-align: center;
	color: #343434;
	text-transform: uppercase;
	font-size: 22px;
	line-height: 130%;
	border-radius: 50%;
	background: #fff;
	width: 140px;
	height: 140px;
	margin: auto;
	box-shadow: inset 0 0 0 5px #76b9b1;
	overflow: hidden;
	font-weight: 800;
	transition: 1s;
	font-family: 'Poppins', sans-serif;

	span {
		display: block;
		font-weight: bolder;
		font-size: 50px;
		margin: 44px 0 10px 0;
	}

	&:hover {
		box-shadow: inset 0 0 0 115px #76b9b1;
		transition: 1s;
	}
}

ul.SideList p.title {
	font-size: 18px;
	color: #fff;
	text-align: center;
	width: 70%;
	margin: 15px auto 0;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

// More links
.SideHeading a.more {
	display: inline-block;
	font-size: 15px;
	padding: 0px;
	width: 150px;
	color: #fff;
	font-size: 18px;
	height: 33px;
	line-height: 33px;
	background: #76b9b1;
	margin: 4px 7%;
	transition: 1s;

	&:hover {
		transition: 1s;
		background: #253672;
	}
}

//Diary
#quote {
	height: 446px;
	width: 100%;
	background: url(/i/design/quote-bg.png) no-repeat;
	background-size: cover;
	overflow: hidden;


	.wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.quote-text {
		color: #fff;
		font-size: 36px;
		font-weight: lighter;
	}
}

#SideHeadingDiary {
	h2 {
		color: #76b9b1;
		text-shadow: 0px 4px 0px rgba(0, 0, 0, 0.2);
		font-weight: 400;
		margin: 39px 0 28px 0;
		font-size: 35px;
		position: relative;
		background: url(/i/design/diary-h2.png) no-repeat bottom center;
		padding: 0 0 17px 0;
		text-indent: 60px;

		&:before {
			content: '';
			position: absolute;
			left: 36%;
			height: 48px;
			width: 48px;
			background: url(/i/design/diary-ic.png) no-repeat;
		}

		span {
			color: #fff;
			font-weight: 700;
		}
	}

	ul.SideList li {
		display: inline-block;
		width: 33%;
	}
}


.ColumnRight>#SideHeadingDiary {
	background: rgba(255, 255, 255, 0.902);

	h2 {
		background: #253672;
		color: #fff;
		padding: 0;
		height: 50px;
		line-height: 50px;
		text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.35);
		font-weight: lighter;
		text-indent: 0px;
		margin: 0 0 10px 0;
		font-size: 25px;

		&:before {
			display: none;
		}
	}

	ul.SideList {
		li {
			width: 100%;
			display: block;
		}

		p.date {
			height: 56px;
			width: 56px;
			float: left;
			font-size: 12.5px;

			span {
				font-size: 22px;
				margin: 14px 0px 0px 0;
			}
		}

		p.title {
			float: right;
			width: 65%;
			color: #201c1c;
			text-align: left;
			margin: 0;
			font-size: 16px;
		}
	}

	a.more {
		background: #253672;
		width: 50%;
		display: block;
		margin: 0;
		float: left;

		&:nth-of-type(2) {
			float: right;
		}
	}
}

//News
#SideHeadingNews {}

//Related pages
#SideHeadingPages {
	background: rgba(255, 255, 255, 0.902);

	h2 {
		background: #76b9b1;
		color: #fff;
		padding: 0;
		height: 50px;
		line-height: 50px;
		text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.35);
		font-weight: lighter;
	}

	ul.SideList li a {
		color: #343434;
		font-size: 16px;
		padding: 8px 25px;

		&:hover {
			background: #76b9b1;
		}
	}

}


// -----------------------------------//
// HOME PAGE ALERT
// -----------------------------------//

//Modal
.modal-home {
	background: rgba(0, 0, 0, .4);
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 8000;

	.message-modal {
		position: absolute;
		top: calc(50% - 300px);
		left: calc(50% - 465px - 40px - 10px);
		width: 930px;
		height: auto;
		margin: 0 30px;
		padding: 20px;
		background: rgba(255, 255, 255, .9);

		.modal-content {
			background: rgba(255, 255, 255, .9);
			background-position: -120px 50px;
			box-shadow: inset 0 0 0 1px #fff;
			display: inline-block;
			width: 100%;
			height: 100%;
			text-align: center;

			.modal-text {
				display: block;
				padding: 45px;

				p {
					margin: 0;
				}
			}

			a.close-modal {
				position: absolute;
				top: 31px;
				right: 31px;
			}
		}
	}
}

.hide-modal {
	display: none !important;
}



// -----------------------------------//
// FOOTER
// -----------------------------------//

footer {
	clear: both;
	background: #fff;
	position: relative;
	height: 330px;
	overflow: hidden;
}

footer>.wrapper {
	text-align: left;
	overflow: hidden;

	h2 {
		color: #343434;
		font-size: 35px;
		text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.35);
		text-align: center;
		position: relative;
		background: url(/i/design/footer-h2.png) no-repeat left bottom;
		text-indent: 20px;
		margin: 40px 0 35px 0;
		padding: 0 0 15px 0;

		&:before {
			content: '';
			position: absolute;
			left: 102px;
			width: 38px;
			height: 39px;
			background: url(/i/design/footer-h2-ic.png) no-repeat;
		}

		span {
			color: #76b9b1;
			font-weight: 900;
		}
	}
}

footer>.wrapper p {
	color: #343434;
	font-size: 18px;
	font-weight: 500;
	position: relative;
	letter-spacing: 0.015em;
}

footer>.wrapper a {}

p.address {
	margin: 0 0 0 33px;

	&:before {
		content: '';
		position: absolute;
		left: -34px;
		top: -4px;
		height: 30px;
		width: 21px;
		background: url(/i/design/location-ic.png) no-repeat;
	}
}

p.telephone {
	margin: 28px 0 0 86px;

	&:before {
		content: '';
		position: absolute;
		left: -31px;
		top: -4px;
		height: 31px;
		width: 24px;
		background: url(/i/design/phone-ic.png) no-repeat;
	}
}

.left-footer {
	width: 50%;
	height: 100%;
	overflow: hidden;
	background: url(/i/design/awards-bg.png) no-repeat bottom 8px left 54px;
}

a.footer-email {
	width: 150px;
	height: 33px;
	line-height: 33px;
	display: block;
	background: #76b9b1 url(/i/design/mail-ic.png) no-repeat center left 20px;
	color: #fff;
	text-align: center;
	text-indent: 42px;
	font-size: 18px;
	margin: -31px 0 0 258px;
	position: relative;
	z-index: 999;
	transition: 1s;

	&:hover {
		background: #253672;
		transition: 1s;
	}
}

#credit {
	margin: 0;
	padding: 0;
	float: right;
	text-align: right;
}

#map {
	position: absolute;
	right: 0;
	top: 0;
	width: 50%;
	height: 100%;
}

.sub-footer {
	height: 30px;
	width: 100%;
	line-height: 30px;
	background: #343434;
	overflow: hidden;
	font-weight: lighter;

	p {
		font-size: 13px;
		color: #fff;
		float: left;
		margin: 0;
	}

	a {
		color: #3ca794;
	}
}

// -----------------------------------//
// CMS CONTENT: TABLES
// -----------------------------------//

$table-highlight-color: darkblue;
$table-highlight-background-color: lightblue;
$table-highlight-font-weight: bold;
$table-border-color: darkblue;

// -----------------------------------//
// CMS CONTENT: BLOCKQUOTES
// -----------------------------------//

blockquote p {
	font-family: 'Raleway', sans-serif;
	font-style: italic;
}

$blockquote-background-color: #fff;
$blockquote-border-color: #EBEBEB;
$blockquote-color: #343434;
$blockquote-speech-mark-color: #000;
$blockquote-font-size: 1em;

// -----------------------------------//
// CALENDAR TABLE BACKGROUND
// -----------------------------------//

.fc-content {
	background: none;
}


// -----------------------------------//
// BROWSER DETECTION
// -----------------------------------//

#browserblast {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 40px;
	border-bottom: 1px solid #D4C790;
	font-family: sans-serif;
	font-size: 12px;
	font-weight: bold;
	line-height: 40px;
	color: #6d6647;
	text-align: center;
	background: #FAF4AF;
}

#browserblast a {
	color: green;
	text-decoration: underline;
}

.unsupported-browser {
	margin-top: 41px;
}

// -----------------------------------//
// FAQ SCRIPT
// -----------------------------------//

// h3.faq-open { background: #EFEFEF url(/i/design/expanding-faq/minus.png) right 12px center no-repeat; }
// h3.faq-closed { background: #EFEFEF url(/i/design/expanding-faq/plus.png) right 12px center no-repeat; }

// -----------------------------------//
// SWITCH MOBILE
// -----------------------------------//

#switch-mobile {
	width: 100%;
	margin: 0;
	padding: 20px 0;
	background: #EFEFEF;
	border-top: 1px solid #EBEBEB;
	border-bottom: 1px solid #EBEBEB;
	text-align: center;
	color: #777;
	font-family: sans-serif;
	font-size: 12px;
	display: block;
	clear: both;
}

#switch-mobile a {
	text-decoration: underline;
}